import React from 'react';
import {BrowserRouter, Switch} from 'react-router-dom';

import {GuestRoutes, UserRoutes} from './components';
import * as scenes from './scenes';
import {AuthCallback} from '../auth';
import {UserRoles} from "./enums";
import {UnknownPage} from "./scenes/ErrorPages";


export default () => {
    return (
        <BrowserRouter>
            <Switch>
                {/* Auth routes */}
                <GuestRoutes exact path='/' component={AuthCallback}/>
                <GuestRoutes exact path='/callback' component={AuthCallback}/>
                <UserRoutes exact path='/nodes' component={scenes.ContractSelection} useGuestLayout authorizedRoles={[UserRoles.COLLABORATOR, UserRoles.ADMINISTRATOR]}/>

                {/* Home routes */}
                <UserRoutes exact path='/nodes/:contract/home' component={scenes.Home} authorizedRoles={[UserRoles.COLLABORATOR, UserRoles.ADMINISTRATOR]}/>

                {/* Users routes */}
                <UserRoutes exact path='/nodes/:contract/users' component={scenes.Participants} authorizedRoles={[UserRoles.ADMINISTRATOR]}/>

                {/* Orders routes */}
                <UserRoutes exact path='/nodes/:contract/orders' component={scenes.Orders} authorizedRoles={[UserRoles.COLLABORATOR]}/>

                {/* Points routes */}
                <UserRoutes exact path='/nodes/:contract/points' component={scenes.Points} authorizedRoles={[UserRoles.COLLABORATOR, UserRoles.ADMINISTRATOR]}/>

                {/* Challenges routes */}
                <UserRoutes exact path='/nodes/:contract/challenges' component={scenes.Challenges} authorizedRoles={[UserRoles.COLLABORATOR]}/>

                {/* Help routes */}
                <UserRoutes exact path='/nodes/:contract/help' component={scenes.Help} authorizedRoles={[UserRoles.COLLABORATOR]}/>

                {/* Administration routes */}
                <UserRoutes exact path='/nodes/:contract/administration/:tab' component={scenes.Admin} authorizedRoles={[UserRoles.ADMINISTRATOR]}/>

                {/* Account activation routes */}
                <GuestRoutes exact path='/nodes/:contract/activate' component={scenes.AccountActivation}/>
                <GuestRoutes exact path='/nodes/:contract/finalize-activation' component={scenes.AccountActivationFinalization}/>
                <GuestRoutes exact path='/nodes/:contract/account-activation-key-expired' component={scenes.AccountActivationKeyExpired}/>
                <GuestRoutes exact path='/nodes/:contract/inaccessible' component={scenes.ContractInaccessible}/>


                {/* To keep at last position (404) */}
                <GuestRoutes path='*' component={UnknownPage}/>
            </Switch>
        </BrowserRouter>
    );
};
