import React from 'react'
import {Button} from "../../../../components";
import {useIntl} from "react-intl";
import {Typography} from "@material-ui/core";
import {useHistory} from "react-router-dom";
import UnknownPageImage from '../../../../assets/img/illustration-404.png'
import {withStyles} from "@mui/styles";
import {neutralColors} from "../../../../themes";

const styles = {
    root: {
        display: 'flex',
        flexDirection: 'flex',
        alignItems: 'center',
        justifyContent: 'space-evenly',
        width: '100%',
    },
    leftContent: {
        display: 'flex',
        flexDirection: 'column',
        gap: '2rem'
    },
    description: {
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem'
    }
}

const UnknownPage = ({ ...props }) => {
    const intl = useIntl()
    const history = useHistory();

    const goBackHome = () => {
        history.push('/nodes')
    }

    return (
        <div className={props.classes.root}>
            <div className={props.classes.leftContent}>
                <div className={props.classes.description}>
                    <Typography variant={'h1'} component={'h1'} className={'underline-left'}>
                        {intl.formatMessage({id: 'spider.errorPages.unknownPage.title'})}
                    </Typography>
                    <Typography variant={'body2'} style={{ color: neutralColors.neutral600 }}>
                        {intl.formatMessage({id: 'spider.errorPages.unknownPage.description'})}
                    </Typography>
                </div>
                <Button variant={'contained'} color={'primary'} size={'small'} onClick={goBackHome}>
                    {intl.formatMessage({id: 'spider.errorPages.unknownPage.backButtonLabel'})}
                </Button>
            </div>
            <img src={UnknownPageImage} alt={'404 image'}/>
        </div>
    )
}

export default withStyles(styles)(UnknownPage)
