import {useAuth} from "../../auth";
import React, {useEffect, useState} from "react";
import {Cup, Home2, InfoCircle, Ranking, Setting2, UserOctagon, ShoppingCart} from "iconsax-react";
import {useParams} from "react-router-dom";
import {useIntl} from "react-intl";
import {UserRoles} from "../enums";

const UseAuthorizedRoutes = () => {
    const { hierarchyNodeUser } = useAuth();
    const {contract, tab} = useParams();
    const intl = useIntl();

    const adminMenuItems = [
        {
            text: intl.formatMessage({id: 'spider.sidebar.admin.home'}),
            icon: <Home2/>,
            src: `/nodes/${contract}/home`,
        },
        {
            text: intl.formatMessage({id: 'spider.sidebar.admin.participants'}),
            icon: <UserOctagon/>,
            src: `/nodes/${contract}/users`,
        },
        {
            text: intl.formatMessage({id: 'spider.sidebar.admin.points'}),
            icon: <Cup/>,
            src: `/nodes/${contract}/points`,
        },
        {
            text: intl.formatMessage({id: 'spider.sidebar.admin.administration'}),
            icon: <Setting2/>,
            src: `/nodes/${contract}/administration/${tab ? tab : 'regulations'}`,
        },
    ];

    const userMenuItems = [
        {
            text: intl.formatMessage({id: 'spider.sidebar.user.home'}),
            icon: <Home2/>,
            src: `/nodes/${contract}/home`,
        },
        {
            text: intl.formatMessage({id: 'spider.sidebar.user.points'}),
            icon: <Cup/>,
            src: `/nodes/${contract}/points`,
        },
        {
            text: intl.formatMessage({id: 'spider.sidebar.user.orders'}),
            icon: <ShoppingCart/>,
            src: `/nodes/${contract}/orders`,
        },
        {
            text: intl.formatMessage({id: 'spider.sidebar.user.challenges'}),
            icon: <Ranking/>,
            src: `/nodes/${contract}/challenges`,
        },
        {
            text: intl.formatMessage({id: 'spider.sidebar.user.help'}),
            icon: <InfoCircle/>,
            src: `/nodes/${contract}/help`,
        }
    ];

    const [menus, setMenus] = useState([])

    useEffect(() => {
        if (hierarchyNodeUser.role === UserRoles.COLLABORATOR) {
            setMenus(userMenuItems)
        } else if (hierarchyNodeUser.role === UserRoles.ADMINISTRATOR) {
            setMenus(adminMenuItems)
        }
    }, [hierarchyNodeUser, contract, tab]);

    return { menus }
}

export default UseAuthorizedRoutes;
