import React from 'react';
import {
  IconButton,
  Toolbar,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
} from '@material-ui/core';
import CloseIcon from '@mui/icons-material/Close';
import { Link } from 'react-router-dom';
import { neutralColors, themeColors } from '../../../../../../../themes/colors';
import useAuthorizedRoutes from "../../../../../../../hooks/UseAuthorizedRoutes";

const useStyles = makeStyles((theme) => ({
  expandedAppBar: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: themeColors.primaryContrastText,
    zIndex: theme.zIndex.drawer + 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  toolbarContent: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  menuItems: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '0px 16px',
  },
  iconButton: {
    backgroundColor: themeColors.primaryContrastText,
    color: themeColors.primaryRegular,
  },
  separator: {
    borderBottom: `1px solid ${neutralColors.neutral200}`,
    margin: '0px 32px',
  },
}));

const MobileMenu = ({
  handleDrawerToggle,
  selectedIndex,
  handleListeItemClick,
  LogoImg,
}) => {
  const classes = useStyles();
  const { menus } = useAuthorizedRoutes()

  return (
    <div className={classes.expandedAppBar}>
      <Toolbar style={{ justifyContent: 'space-between' }}>
        <img height='48px' src={LogoImg} alt='logo' />
        <IconButton
          aria-label='close drawer'
          onClick={handleDrawerToggle}
          className={classes.iconButton}
        >
          <CloseIcon />
        </IconButton>
      </Toolbar>
      <div className={classes.separator}></div>
      <div className={classes.menuItems}>
        <List>
          {menus.map((item) => (
            <ListItem
              button
              key={item.text}
              selected={selectedIndex === item.src}
              component={Link}
              to={item.src}
              onClick={(event) => handleListeItemClick(event, item.src)}
            >
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText primary={item.text} />
            </ListItem>
          ))}
        </List>
      </div>
    </div>
  );
};

export default MobileMenu;
