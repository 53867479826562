import React, { useEffect, useState } from 'react';
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
import {useHistory, useLocation} from 'react-router-dom';
import {useSelector} from "react-redux";
import {makeStyles} from '@material-ui/core/styles';
import useAuthorizedRoutes from "../../../../hooks/UseAuthorizedRoutes";

const useStyles = makeStyles((theme) => ({
    root: {
        padding: '8px 8px 0px 16px',
        marginBottom: 16,
        flex: 1,
        '& .MuiDrawer-paper': {
            width: 288,
            overflow: 'auto',
            position: 'relative',
            padding: 16,
            borderRadius: 16,
        },
        '& .MuiDrawer-paperAnchorDockedLeft': {
            borderRight: 'none',
        },
    }
}));

const SideBar = () => {
    const classes = useStyles();
    const location = useLocation();
    const history = useHistory();
    const pristineSettingState = useSelector(state => state.settingPristineKey);
    const { menus } = useAuthorizedRoutes()
    const [src, setSrc] = useState(location.pathname);

    useEffect(() => {
        setSrc(location.pathname);
    }, [location.pathname, pristineSettingState.sideBarReset]);

    const handleListeItemClick = (event, srcPayload) => {
        setSrc(srcPayload);
        history.push(srcPayload)
    };

    return (
        <Drawer variant='permanent' anchor='left' className={classes.root}>
          <List>
            {menus.map((item) => (
              <ListItem
                button
                key={item.text}
                selected={src === item.src}
                autoFocus={src === item.src}
                onClick={(event) => handleListeItemClick(event, item.src)}
              >
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText primary={item.text} />
              </ListItem>
            ))}
          </List>
        </Drawer>
    );
};
export default SideBar;
