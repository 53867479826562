import React, {useState} from 'react';
import {withStyles} from "@mui/styles";
import {useIntl} from "react-intl";
import {Tab, Typography} from "@material-ui/core";
import {TabContext, TabList, TabPanel} from "@material-ui/lab";
import {Button} from "../../../../components";
import ImportDialog from "../../../../components/ImportDialog/ImportDialog";
import {ImportLogsTypes} from "../../../../enums/importLogsTypes";
import {ImportLogsHistory} from "../../../../components/importLogsHistory";
import {useHistory, useParams} from "react-router-dom";

const styles = {
    pointsBody: {
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem'
    },
    pointsHeader: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: '1rem'
    }
}

const AdminPoints = ({ ...props }) => {
    const intl = useIntl();
    const history = useHistory();
    const { contract } = useParams();

    const LIST_TAB = { label: intl.formatMessage({id: 'spider.points.list'}), index: '1', url: 'list' }
    const HISTORY_TAB = { label: intl.formatMessage({id: 'spider.points.history'}), index: '2', url: 'history' }

    const [tabValue, setTabValue] = useState(HISTORY_TAB.index);
    const [openImport, setOpenImport] = useState(false);
    const [refreshState, setRefreshState] = useState(false)

    const handleChange = (event, tab) => {
        setTabValue(tab);
    };

    const importPoints = () => {
        setOpenImport(true)
    };

    const refresh = (importLogType) => {
        if (importLogType === ImportLogsTypes.USERS && history.location.pathname.includes('points')) {
            history.push(`/nodes/${contract}/users`)
        } else {
            setRefreshState(!refreshState)
        }
    }

    return (
        <div className={props.classes.pointsBody}>
            <div className={props.classes.pointsHeader}>
                <Typography variant={'h1'} component={'h1'} className={'underline-left'}>
                    {intl.formatMessage({id: 'spider.points.title'})}
                </Typography>
                <Button color={'primary'} variant={'outlined'} size={'large'} onClick={importPoints}>
                    {intl.formatMessage({id: 'spider.points.import'})}
                </Button>
            </div>

            <TabContext value={tabValue}>
                <div className={'tabs-container'}>
                    <TabList onChange={handleChange}>
                        <Tab label={LIST_TAB.label} value={LIST_TAB.index} disableFocusRipple/>
                        <Tab label={HISTORY_TAB.label} value={HISTORY_TAB.index} disableFocusRipple/>
                    </TabList>
                    <TabPanel value={LIST_TAB.index}>
                    </TabPanel>
                    <TabPanel value={HISTORY_TAB.index}>
                        <ImportLogsHistory refreshState={refreshState} importLogType={ImportLogsTypes.POINTS}
                                           emptyLabelParams={intl.formatMessage({id: 'spider.importLog.dataTable.no_data_points'})}/>
                    </TabPanel>
                </div>
            </TabContext>

            <ImportDialog open={openImport} setOpen={setOpenImport} onFileCreated={refresh} defaultTab={'1'}/>
        </div>
    );
};

export default withStyles(styles)(AdminPoints);
