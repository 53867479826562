import React from 'react';
import {useIntl} from "react-intl";
import {Link} from "@material-ui/core";
import {useSelector} from "react-redux";
import {SystemFileCodes, UserRoles} from "../../../../enums";
import {withStyles} from "@mui/styles";
import {useAuth} from "../../../../../auth";
import {createTheme} from "@material-ui/core/styles";
import {toast} from "react-toastify";

const styles = {
    legalDocumentList: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        listStyleType: 'none',
        gap: '0.5rem',
        paddingInlineStart: '0 !important',
        [createTheme().breakpoints.up('md')]: {
            flexDirection: 'row',
            listStyleType: 'disc',
            gap: '0',
            '& li': {
                listStylePosition: 'inside',
                marginLeft: '14px',
            },
        },
    }
}

const Footer = ({ ...props }) => {
    const intl = useIntl();
    const { hierarchyNodeUser } = useAuth()
    const mainState = useSelector(state => state.mainKey);

    const openLink = (link) => {
        window.open(link, '_blank')
    }

    const openLinkByCode  = (documentCode) => {
        if (!mainState || !mainState.legalDocuments || !mainState.legalDocuments.results) {
            return false
        }

        const documentLink = mainState.legalDocuments.results.find((document) => document.code === documentCode)

        if (!documentLink) {
            toast.error(intl.formatMessage({id: 'spider.footer.noDocumentAvailable'}));
            return false
        }

        openLink(documentLink.src)
    }

    return (
        <>
            <footer>
                <div className={'footer-content'}>
                    <ul className={props.classes.legalDocumentList}>
                        <li style={{listStyleType: 'none'}}>
                            { /* TODO: le n° de version */}
                            {intl.formatMessage({id: 'spider.app_name'})} V1.0.0
                        </li>
                        <li key={document.id}>
                            <Link className={'bold small'} onClick={() => openLink('')}>
                                {intl.formatMessage({id: 'spider.footer.generalUseConditions'})}
                            </Link>
                        </li>
                        <li key={document.id}>
                            <Link className={'bold small'} onClick={() => openLinkByCode(SystemFileCodes.RGPD)}>
                                {intl.formatMessage({id: 'spider.footer.dataProtection'})}
                            </Link>
                        </li>
                        <li key={document.id}>
                            <Link className={'bold small'} onClick={() => openLinkByCode(SystemFileCodes.LEGAL_MENTIONS)}>
                                {intl.formatMessage({id: 'spider.footer.legalMentions'})}
                            </Link>
                        </li>
                        { hierarchyNodeUser.role === UserRoles.COLLABORATOR && <li key={document.id}>
                            <Link className={'bold small'} onClick={() => openLinkByCode(SystemFileCodes.REGLEMENT)}>
                                {intl.formatMessage({id: 'spider.footer.platformRegulation'})}
                            </Link>
                        </li> }
                    </ul>
                </div>
            </footer>
        </>
    );
}

export default withStyles(styles)(Footer);
