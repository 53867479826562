import React, { useEffect } from "react";
import { UpdateMobileAppModal } from "../";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useLocalStorage } from "@rehooks/local-storage";
import { UPDATE_REQUIRED } from "../../../../../../../../data/local/localTypes";
import tokens from "../../../../../../../../data/api/endpoints/tokens";
import { toast } from "react-toastify";
import _, { set } from "lodash";
import local from "../../../../../../../../data/local/local";
import Intercom from "@intercom/messenger-js-sdk";

const MainEvents = ({ goBack, children, ...props }) => {
    const { account } = props.accountDetail;

    const [updateRequired, setUpdateRequired] = useLocalStorage(UPDATE_REQUIRED);

    // the type for updateRequired may be string or boolean, use very defensive casting
    // eslint-disable-next-line eqeqeq
    const updateRequiredBool = String(updateRequired).toLowerCase() == "true";

    useEffect(() => {
        // reset update required when initializing app
        setUpdateRequired("false");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [account]);

    const environmentName = local.getClientEnvironment();

    useEffect(() => {
        if (account) {
            const intercomSettings = {
                app_id: process.env.REACT_APP_INTERCOM_ID,
                user_id: account.id,
                name: account.fullname,
                email: account.email,
                company: {
                    id: environmentName,
                    name: environmentName,
                },
                account_role: account.role.name,
            };

            Intercom(intercomSettings);
        }
    }, [account, environmentName]);

    return (
        <>
            {updateRequiredBool && <UpdateMobileAppModal />}
            {children}
        </>
    );
};

const mapStateToProps = ({ accountDetail }) => ({
    accountDetail,
});

export default connect(mapStateToProps)(MainEvents);
