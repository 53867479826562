import React, {useState} from 'react'
import {Typography} from "@material-ui/core";
import {useIntl} from "react-intl";
import {withStyles} from "@mui/styles";
import {Button} from "../../../index";
import {RequirementHelp} from "../../../RequirementHelp";
import {Accordion, AccordionDetails, AccordionSummary} from "@mui/material";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {DocumentDownload, Flash} from "iconsax-react";
import FileInputDragAndDrop from "../../../FileInputDragAndDrop/FileInputDragAndDrop";
import {createTheme} from "@material-ui/core/styles";
import {neutralColors as neutralsColors} from "../../../../themes";

const styles = {
    importContent: {
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem'
    },
    importContentHeader: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '1rem',
        [createTheme().breakpoints.up('md')]: {
            flexDirection: 'row',
            justifyContent: 'space-between',
        },
    },
    importContentSubtitle: {
        textAlign: 'center',
        [createTheme().breakpoints.up('md')]: {
            textAlign: 'left',
        },
    },
    accordionSummary: {
        '& .MuiAccordionSummary-content': {
            display: 'flex',
            flexDirection: 'row',
            gap: '1rem'
        }
    }
}

const ImportContent = ({ title, subtitle, downloadPattern, requirementObject, validateImport, ...props }) => {
    const intl = useIntl();
    const [helpExpanded, setHelpExpanded] = useState(true);
    const [files, setFiles] = useState([]);
    const minSize = 0
    const maxSize = 10
    const multiple = false
    const authorizedFileTypes = ['CSV']

    const clearState = () => {
        setFiles([])
    }

    const fileChanged = (files) => {
        clearState()

        setHelpExpanded(false)
        if (!files || files.find(file => !file) !== undefined) return

        setFiles(files)
    };

    return (
        <div className={props.classes.importContent}>
            <div className={props.classes.importContentHeader}>
                <Typography variant={'h2'} component={'h2'} className={'title'}>
                    {title}
                </Typography>

                <Button color={'primary'} variant={'outlined'} onClick={downloadPattern}>
                    <DocumentDownload/>
                    {intl.formatMessage({id: 'spider.importDialog.download'})}
                </Button>
            </div>

            <div className={props.classes.importContentSubtitle}>
                <Typography variant={'body2'}>
                    {subtitle}
                </Typography>
            </div>

            <FileInputDragAndDrop minSize={minSize}
                                  maxSize={maxSize}
                                  multiple={multiple}
                                  authorizedFileTypes={authorizedFileTypes}
                                  customInfo={intl.formatMessage({id: 'spider.importDialog.importInformation'})}
                                  customError={intl.formatMessage({id: 'spider.importDialog.importError'})}
                                  fileOrFilesChanged={fileChanged}
            />

            <Accordion expanded={helpExpanded} onClick={() => setHelpExpanded(!helpExpanded)}
                       style={{ borderRadius: '1rem', border: `2px solid ${neutralsColors.neutral450}`, boxShadow: 'none' }}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    className={props.classes.accordionSummary}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Flash/>
                    <Typography variant={'h3'} component={'h3'}>
                        {intl.formatMessage({id: 'spider.importDialog.help'})}
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <RequirementHelp requirementObject={requirementObject}/>
                </AccordionDetails>
            </Accordion>

            {files && files.length > 0 && <Button color={'primary'} variant={'contained'} size={'small'}
                     style={{width: 'fit-content', margin: 'auto'}} onClick={() => validateImport(files)}>
                {intl.formatMessage({id: 'common.submit'})}
            </Button>}
        </div>
    )
}

export default withStyles(styles)(ImportContent);
