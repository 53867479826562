import React, {useEffect} from 'react'
import {withStyles} from "@mui/styles";
import {Chip, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography} from "@material-ui/core";
import {RowsPerPageSelector} from "../RowsPerPageSelector";
import {systemColors} from "../../themes";
import {ImportLogsStatuses} from "../../enums/importLogsStatuses";
import {Button} from "../index";
import {DocumentDownload} from "iconsax-react";
import Pagination from "@material-ui/lab/Pagination";
import {useIntl} from "react-intl";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import useImportLogFile from "../../hooks/UseImportLogFile";
import usePagination from "../../hooks/UsePagination";
import {getHistoryImportsKeyStart} from "../../features/importLogs/historyImports/slices";
import HistoryNoteEmptyData from '../../assets/img/empty-import-logs-note.png';
import {Loader} from "../../../components";

const styles = {
    root: {
        '&.MuiPaper-root': {
            boxShadow: 'none'
        }
    },
    emptyData: {
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'center',
        gap: '1rem'
    }
}

const ImportLogsHistory = ({ refreshState, importLogType, emptyLabelParams, ...props }) => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const { contract } = useParams();
    const historyImport = useSelector(state => state.getHistoryImportsKey)

    const { getChipsLabel, getChipsStatus, exportImportLog } = useImportLogFile({ onFileExported: () => {} });

    const download = (row) => {
        exportImportLog(row.uuid, contract)
    }

    const { pagination, handleChangePage, handleChangeRowsPerPage, paginationCount } =
        usePagination({ dataLength: historyImport.success ? historyImport.imports.count : 0 });

    useEffect(() => {
        dispatch(getHistoryImportsKeyStart({ teamGroupId: contract, types: [importLogType], pagination }));
    }, [refreshState, pagination.page, pagination.rowsPerPage]);

    const columns = [
        { field: 'date', label: intl.formatMessage({id: 'spider.points.columns.date'}) },
        { field: 'name', label: intl.formatMessage({id: 'spider.points.columns.name'}) },
        { field: 'lines', label: intl.formatMessage({id: 'spider.points.columns.lines'}) },
        { field: 'status', label: intl.formatMessage({id: 'spider.points.columns.status'}) },
        { field: 'action', label: intl.formatMessage({id: 'spider.points.columns.action'}) },
    ];

    return (
        <Paper className={props.classes.root}>
            <RowsPerPageSelector rowsPerPage={pagination.rowsPerPage} handleChangeRowsPerPage={handleChangeRowsPerPage}/>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            { columns.map((column) => {
                                return (<TableCell key={column.id}>{column.label}</TableCell>)
                            }) }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {historyImport.loading ? (<TableRow>
                                <Loader centered />
                            </TableRow>) :
                        (historyImport.success && historyImport.imports.results?.length > 0 ? (
                            historyImport.imports.results.map((row, rowIndex) => (
                                <TableRow key={rowIndex}>
                                    <TableCell>
                                        <div>
                                            { row.created_at.toDate().toLocaleString('fr-FR', { day: '2-digit', month: '2-digit', year: 'numeric' }).replaceAll('/', '.') }
                                        </div>
                                    </TableCell>
                                    <TableCell>
                                        <div>
                                            {row.input_file.filename.replace('.csv', '')}
                                            <br/>
                                            <div style={{fontSize: '10px'}}>{`${row.creator.firstname} ${row.creator.lastname}`}</div>
                                        </div>
                                    </TableCell>
                                    <TableCell>
                                        <div>
                                            {row.nbTotalLines}
                                            <br/>
                                            {row.linesError > 0 ? <div style={{
                                                fontSize: '10px',
                                                color: systemColors.errorDark
                                            }}>{`dont ${row.nbFailedLines} en erreur`}</div> : ''}
                                        </div>
                                    </TableCell>
                                    <TableCell>
                                        <Chip label={getChipsLabel(row.status)} className={getChipsStatus(row.status)}/>
                                    </TableCell>
                                    <TableCell>
                                        {[ImportLogsStatuses.FINISHED].includes(row.status) && <Button variant={'contained'} color={'primary'} className={'size-tiny icon'}
                                                                                                       onClick={() => download(row)}>
                                            <DocumentDownload size={24}/>
                                        </Button>}
                                    </TableCell>
                                </TableRow>
                            ))
                        ) : (
                            <TableRow>
                                <TableCell colSpan={columns.length} align='center'>
                                    <img src={HistoryNoteEmptyData} alt='empty'/>
                                    <div className={props.classes.emptyData}>
                                        <Typography variant={'h4'} component={'h4'}>
                                            {intl.formatMessage({id: 'spider.importLog.dataTable.no_data_available_title',})}
                                        </Typography>
                                        <Typography variant={'body2'}>
                                            {intl
                                                .formatMessage({id: 'spider.importLog.dataTable.no_data_available_description'})
                                                .format(emptyLabelParams)}
                                        </Typography>
                                    </div>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Pagination
                count={paginationCount}
                page={pagination.page}
                onChange={handleChangePage}
                shape='rounded'
            />
        </Paper>
    );
}

export default withStyles(styles)(ImportLogsHistory);
