import {Dialog} from "@material-ui/core";
import React from "react";
import {withStyles} from "@material-ui/core/styles";
import withWidth from "@material-ui/core/withWidth";
import {neutralColors} from "../../themes";

const styles = {
    paper: {
        position: 'absolute',
        maxHeight: '100vh',
        maxWidth: '100vw !important',
        padding: 0,
        margin: 0,
        borderRadius: '1rem 1rem 0rem 0rem',
        borderTop: `4px solid ${neutralColors.neutral300}`,
        borderRight: `4px solid ${neutralColors.neutral300}`,
        borderLeft: `4px solid ${neutralColors.neutral300}`,
        bottom: 0,
        height: '90vh',
        width: '100vw',
        '& > div:first-child, & > form > div:first-child': {
            marginTop: 0,
            paddingTop: 0,
        },
        '& > div:nth-child(2), & > div:nth-child(3), & > form > div:nth-child(2), & > form > div:nth-child(3)': {
            marginTop: 0,
        },
    }
};

const DialogComponent = withStyles(styles)(Dialog);

const ScreenBottomDialog = ({ width = 'xs', direction, ...props }) => {
    return (
        <React.Fragment>
            <DialogComponent {...props}/>
        </React.Fragment>
    );
}

export default withWidth()(ScreenBottomDialog);
