import React from 'react';
import {useAuth} from "../../../auth";
import {UserRoles} from "../../enums";
import {AdminHome} from "./components/AdminHome";
import {UserHome} from "./components/UserHome";

const Points = () => {
    const { hierarchyNodeUser } = useAuth();

    return (
        <>
            { hierarchyNodeUser.role === UserRoles.ADMINISTRATOR && (<AdminHome/>) }
            { hierarchyNodeUser.role === UserRoles.COLLABORATOR && (<UserHome/>) }
        </>
    );
};

export default Points;
