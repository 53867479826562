import React from 'react'
import CloseIconSvg from '../../assets/img/close-icon.svg'

const CloseIcon = () => {
    return (
        <img src={CloseIconSvg} alt={'close-icon'}/>
    )
}

export default CloseIcon;
