import React from 'react'
import {withStyles} from "@mui/styles";
import {Link, Paper, Typography} from "@material-ui/core";
import {useDispatch, useSelector} from "react-redux";
import {useIntl} from "react-intl";
import {useHistory} from "react-router-dom";
import {mainKeyClear} from "../../../../features/main/slices";

const styles = {
    root: {
        padding: '8px 8px 0px 16px',
        marginBottom: 16,
        '& .MuiPaper-root': {
            display: 'flex',
            flexDirection: 'column',
            gap: '0.5rem',
            boxShadow: 'none !important',
            width: 288,
            overflow: 'auto',
            position: 'relative',
            padding: '0.5rem 1rem',
            borderRadius: 16,
        },
    },
    hierarchyNodeLogo: {
        height: '3.5rem',
        width: '3.5rem',
        objectFit: 'cover'
    },
    hierarchyNodeInfo: {
        display: 'flex',
        flexDirection: 'row',
        gap: '0.5rem',
    },
    hierarchyNodeDetails: {
        display: 'flex',
        flexDirection: 'column'
    },
    hierarchyNodeAction: {
        display: 'flex',
        justifyContent: 'center'
    }
}

const OperationDialog = ({ ...props }) => {
    const intl = useIntl();
    const history = useHistory();
    const dispatch = useDispatch();
    const currentHierarchyNode = useSelector(state => state.mainKey.hierarchyNode);

    const toNodes = () => {
        history.push('/nodes')
        dispatch(mainKeyClear())
    }

    return (
        <div className={props.classes.root}>
            { currentHierarchyNode && <Paper>
                <div className={props.classes.hierarchyNodeInfo}>
                    <img src={currentHierarchyNode._context && currentHierarchyNode._context.logo ? currentHierarchyNode._context.logo.src : null}
                         alt='platform-logo' className={props.classes.hierarchyNodeLogo}
                    />
                    <div className={props.classes.hierarchyNodeDetails}>
                        <Typography variant={'h4'} component={'h4'}>{currentHierarchyNode.name}</Typography>
                        <Typography>{currentHierarchyNode.description}</Typography>
                    </div>
                </div>

                <div className={props.classes.hierarchyNodeAction}>
                    <Link className={'bold small'} onClick={toNodes} style={{ textDecoration: 'none' }}>
                        {intl.formatMessage({id: 'spider.org.contract_selection.switch'})}
                    </Link>
                </div>
            </Paper> }
        </div>
    )
}

export default withStyles(styles)(OperationDialog);
